.react-datepicker {
    font-family: inherit !important;
    border: none !important;
    background: transparent !important;
    font-size: 0.875rem !important; /* text-sm */
}

.react-datepicker__month-container {
    background: white;
}

.react-datepicker__header {
    background: white !important;
    border-bottom: none !important;
    padding: 0 !important;
}

.react-datepicker__day-names {
    margin-top: 4px; /* reduced from 8px */
}

.react-datepicker__day-name {
    color: #6B7280 !important;
    font-size: 0.75rem !important; /* text-xs */
    font-weight: 500;
    width: 32px !important; /* reduced from 36px */
    height: 32px !important;
    line-height: 32px !important;
    margin: 1px !important; /* reduced from 2px */
}

.react-datepicker__day {
    width: 32px !important; /* reduced from 36px */
    height: 32px !important;
    line-height: 32px !important;
    margin: 1px !important;
    font-size: 0.875rem !important; /* text-sm */
    border-radius: 4px !important;
    color: #374151;
}

.react-datepicker__day:hover {
    background-color: #F3F4F6 !important;
}

.react-datepicker__day--in-range {
    background-color: #E5E7EB !important;
    color: #374151 !important;
    position: relative;
}

/* Add styles for the range background effect */
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, 
.react-datepicker__day--range-start, 
.react-datepicker__day--range-end) {
    background-color: #E5E7EB !important;
}

/* Start date styles */
.react-datepicker__day--range-start {
    background-color: black !important;
    color: white !important;
    border-radius: 4px 0 0 4px !important;
    position: relative;
}

/* End date styles */
.react-datepicker__day--range-end {
    background-color: black !important;
    color: white !important;
    border-radius: 0 4px 4px 0 !important;
    position: relative;
}

/* When start and end are the same day */
.react-datepicker__day--range-start.react-datepicker__day--range-end {
    border-radius: 4px !important;
}

/* Add a light background for the range */
.react-datepicker__day--in-range:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end) {
    background-color: #E5E7EB !important;
    border-radius: 0 !important;
}

/* Hover effect for days */
.react-datepicker__day:hover:not(.react-datepicker__day--selected, 
.react-datepicker__day--range-start, 
.react-datepicker__day--range-end) {
    background-color: #D1D5DB !important;
}

/* Selected day style */
.react-datepicker__day--selected {
    background-color: black !important;
    color: white !important;
}

/* Today's date highlight */
.react-datepicker__day--today {
    font-weight: 600;
}

/* Previous month and next month dates */
.react-datepicker__day--outside-month {
    opacity: 0.3 !important;
    pointer-events: none !important;
}

/* Future dates */
.react-datepicker__day--future {
    opacity: 0.3 !important;
    pointer-events: none !important;
}

/* Disabled dates (including future dates) */
.react-datepicker__day--disabled {
    opacity: 0.3 !important;
    color: #374151 !important;
    pointer-events: none !important;
    cursor: default !important;
}

/* Override hover effect for disabled dates */
.react-datepicker__day--disabled:hover {
    background-color: transparent !important;
}

.react-datepicker__triangle {
    display: none;
}

/* Update the container padding in DateRangeSelector component */
.react-datepicker-wrapper {
    padding: 0.75rem !important; /* p-3 instead of p-4 */
}

/* Make preset buttons smaller */
.react-datepicker__preset-button {
    padding: 0.5rem 0.75rem !important; /* py-2 px-3 -> py-1.5 px-2 */
    font-size: 0.875rem !important;
}