/* src/index.css or src/App.css */

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600&family=Inter:wght@400;500;600&family=Lato:wght@400;500;600&family=Manrope:wght@400;500;600&family=Montserrat:wght@400;500;600&family=Open+Sans:wght@400;500;600&family=Outfit:wght@400;500;600&family=Playfair+Display:wght@400;500;600&family=Plus+Jakarta+Sans:wght@400;500;600&family=Poppins:wght@400;500;600&family=Roboto:wght@400;500;600&family=Sora:wght@400;500;600&family=Source+Sans+Pro:wght@400;600&family=Space+Grotesk:wght@400;500;600&family=Work+Sans:wght@400;500;600&display=swap');
@import 'react-datepicker/dist/react-datepicker.css';

@import './ui/src/assets/style/scrollbar.css';
@import './ui/src/assets/style/datePicker.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset everything to full width */
html,
body,
#root,
#root>div {
  width: 100vw !important;
  max-width: 100vw !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add the new drag-and-drop styles */
.dragging {
  opacity: 0.5;
  border: 2px dashed #3b82f6;
  background: #fff;
}

.question-item {
  transition: transform 0.2s ease;
}

.question-item:hover {
  transform: translateX(2px);
}

/* Survey scroll prevention */
body.survey-active {
  overflow: hidden !important;
  position: fixed;
  width: 100%;
  height: 100%;
  touch-action: none;
  -webkit-overflow-scrolling: none;
}

/* Add this for the survey container */
.survey-container {
  min-height: 100vh;
  width: 100%;
}

/* Desktop-specific styles */
body.survey-active-desktop {
  overflow: hidden !important;
  position: fixed;
  width: 100%;
  height: 100%;
}

/* Mobile-specific styles */
@media (hover: none) and (pointer: coarse) {
  .survey-container {
    min-height: 100vh;
    width: 100%;
  }
}

/* Add font-family classes for preview */
.font-inter {
  font-family: 'Inter', sans-serif;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-opensans {
  font-family: 'Open Sans', sans-serif;
}

.font-lato {
  font-family: 'Lato', sans-serif;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.font-playfair {
  font-family: 'Playfair Display', serif;
}

.font-dmsans {
  font-family: 'DM Sans', sans-serif;
}

.font-sourcesans {
  font-family: 'Source Sans Pro', sans-serif;
}

.font-manrope {
  font-family: 'Manrope', sans-serif;
}

.font-worksans {
  font-family: 'Work Sans', sans-serif;
}

.font-spacegrotesk {
  font-family: 'Space Grotesk', sans-serif;
}

.font-plusjakarta {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.font-outfit {
  font-family: 'Outfit', sans-serif;
}

.font-sora {
  font-family: 'Sora', sans-serif;
}