/* Add these styles to customize scrollbars throughout the app */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

/* For Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #f1f1f1;
}